import React from "react";
import "./CampaignTab.css";

const CampaignTab = () => {
    function openTab(e) {
        e.preventDefault();

        let buttons = document.getElementsByClassName("cmp__tabbutton");
        let tabs = document.getElementsByClassName("cmp__tab--desc");
        // console.log(buttons);

        for (let i = 0; i < buttons.length; i++) {
            // console.log(buttons[i]);

            if (tabs[i].classList.contains("cmp__tab--desc--hidden")) {
                tabs[i].classList.remove("cmp__tab--desc--hidden");
            }

            if (buttons[i].classList.contains("cmp__tabbutton--active")) {
                buttons[i].classList.remove("cmp__tabbutton--active");
                tabs[i].style.display = "none";
            }
        }

        e.target.classList.add("cmp__tabbutton--active");

        // console.log(e.target.innerText);
        if (e.target.innerText === "ΠΡΟΓΡΑΜΜΑ") {
            // console.log(1);
            document.getElementById("cmp__tab__id--programa").style.display = "block";
            document.getElementById("cmp__tab__id--draseis").style.display = "none";
        } else if (e.target.innerText === "ΔΡΑΣΕΙΣ") {
            // console.log(2);
            document.getElementById("cmp__tab__id--draseis").style.display = "block";
            document.getElementById("cmp__tab__id--programa").style.display = "none";
        }
    }

    return (
        <>
            <div className="cmp__tabbar">
                <button className="cmp__tabbutton cmp__tabbutton--active" onClick={openTab}>
                    Προγραμμα
                </button>

                {
                    // <button className="cmp__tabbutton" onClick={openTab}>
                    //     Δρασεις
                    // </button>
                }
            </div>

            <div id="cmp__tab__id--programa" className="cmp__tab--desc">
                <div className="cmp__tab__pg__slot">
                    <h3 className="cmp__tab__pg--time">14:00</h3>
                    <p className="cmp__tab__pg--desc">Καλωσόρισμα Προέδρου</p>
                </div>
                <div className="cmp__tab__pg__slot">
                    <h3 className="cmp__tab__pg--time">14:05</h3>
                    <p className="cmp__tab__pg--desc">Παιχνίδι «Μάντεψε ποιος»</p>
                </div>
                <div className="cmp__tab__pg__slot">
                    <h3 className="cmp__tab__pg--time">14:15</h3>
                    <p className="cmp__tab__pg--desc">
                        Γνωριμία-Ανοικτή συζήτηση με τους ερευνητές (A’ μέρος)
                    </p>
                </div>
                <div className="cmp__tab__pg__slot">
                    <h3 className="cmp__tab__pg--time">15:00</h3>
                    <p className="cmp__tab__pg--desc">Παιχνίδι «Τα ερευνητικά πεδία παίζει»</p>
                </div>
                <div className="cmp__tab__pg__slot">
                    <h3 className="cmp__tab__pg--time">15:10</h3>
                    <p className="cmp__tab__pg--desc">
                        Ανοικτή συζήτηση με τους ερευνητές (Β’ μέρος)
                    </p>
                </div>
                <div className="cmp__tab__pg__slot">
                    <h3 className="cmp__tab__pg--time">15:30</h3>
                    <p className="cmp__tab__pg--desc">Μουσική, συζήτηση, φαγητό και ποτό</p>
                </div>
            </div>

            <div id="cmp__tab__id--draseis" className="cmp__tab--desc cmp__tab--desc--hidden">
                <div className="cmp__tab__pg__slot">
                    {/*
                        <h3 className="cmp__tab__pg--time">09:00 - 09:30</h3>
                    */}

                    <p className="cmp__tab__pg--desc">
                        «Ο νέος είναι ωραίος…ο παλιός είναι αλλιώς»
                    </p>

                    <p className="cmp__tab__pg--desc">Παιχνίδι ερωτήσεων: Μάντεψε ποιος</p>

                    <p className="cmp__tab__pg--desc">Αγαπημένες Μουσικές</p>
                </div>

                {/*
                <div className="cmp__tab__pg__slot">
                    <h3 className="cmp__tab__pg--time">09:30 - 10:00</h3>
                    <p className="cmp__tab__pg--desc">Συνοπτική παρουσίαση αποτελεσμάτων</p>
                </div>
                */}
            </div>
        </>
    );
};

export default CampaignTab;
