import React from "react";
import styles from "./Researcher.module.css";
import "../../css/typography.css";
import { Link } from "react-router-dom";

const Researcher = (props) => {
    return (
        <figure className={`${styles.researcher}`}>
            <Link to={`/${props.path}`} className={`${styles["researcher__link"]}`}>
                <img src={props.rc_img} alt={props.alt || "Researcher"} />
                <blockquote className="researcher__link__text">
                    <span className="researcher__link__text--primary">{props.rcTxtPrimary}</span>
                    <span className="researcher__link__text--secondary">
                        {props.rcTxtSecondary}
                    </span>
                </blockquote>
            </Link>
        </figure>
    );
};

export default Researcher;
