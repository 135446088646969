import React from "react";
// import styles from "./RC.module.css";
import "../css/utilities.css";

import RCNavHeader from "./rcNav/RCNavHeader";
import RCHero from "./rcHero/RCHero";
import RCBlog from "./rcBlog/RCBlog";
import RCNavFooter from "./rcNav/RCNavFooter";
import Footer from "../general/footer/Footer";

import ScrollToTop from "../general/scrollToTop";
import ScrollToLink from "../general/scrollToLink";

const RC = (props) => {
    return (
        <>
            <ScrollToTop />

            <div className="u-bg-img-wrap-outer">
                <div className="u-bg-img-wrap-inner">
                    <header>
                        <RCNavHeader prev_link={props.prev_link} next_link={props.next_link} />
                    </header>
                    <main className="u-margin-bottom-l">
                        <RCHero
                            className="u-margin-bottom-xl"
                            rc_img={props.rc_img}
                            rcMoto={props.rcMoto}
                            rcQuote={props.rcQuote}
                            rcTxtPrimary={props.rcTxtPrimary}
                            rcTxtSecondary={props.rcTxtSecondary}
                            rcTxtInstTitle={props.rcTxtInstTitle}
                        />
                        <RCBlog
                            rcQA={props.rcQA}
                            video_src={props.video_src}
                            links={props.links}
                            links2={props.links2}
                            path={props.path}
                        />
                    </main>
                    <RCNavFooter prev_link={props.prev_link} next_link={props.next_link} />
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default RC;
