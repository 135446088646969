import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RC from "./react-components/pages/rc/RC";
import Campaign from "./react-components/pages/campaign/Campaign";
import researchers from "./researcherData";
import AppResearchers from "./AppResearchers";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App researchers={researchers} />} />
            <Route path="/campaign" element={<Campaign />} />
            {/*
            <Route path="/" element={<Campaign />} />
            <Route path="/researchers" element={<App researchers={researchers} />} />
            */}
            {researchers.map((researcher, i, arr) => {
                return (
                    <Route
                        path={researcher.path}
                        element={
                            <RC
                                rc_img={researcher.rc_img_lg}
                                rcMoto={researcher.rcMoto}
                                rcQuote={researcher.rcQuote}
                                rcTxtPrimary={researcher.rcTxtPrimary}
                                rcTxtSecondary={researcher.rcTxtSecondary}
                                rcTxtInstTitle={researcher.rcTxtInstTitle}
                                rcQA={researcher.rcQA}
                                video_src={researcher.video_src}
                                links={researcher.links}
                                links2={researcher.links2}
                                prev_link={i > 0 ? "/" + arr[i - 1].path : ""}
                                next_link={i + 1 < arr.length ? "/" + arr[i + 1].path : ""}
                                path={researcher.path}
                            />
                        }
                        key={researcher.path + "-rc"}
                    />
                );
            })}
        </Routes>
    </BrowserRouter>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
