import React from "react";
import "./CampaignEvent.css";

import CampaignTab from "../campaign-tabnav/CampaignTab";

const CampaignEvent = () => {
    return (
        <div>
            <div className="cmp__ev__g">
                <div className="cmp__ev__g__left">
                    <h3 className="cmp__h__primary">Η εκδηλωση</h3>
                    <p className="cmp__p">
                        Το ΕΚ «Αθηνά» γιορτάζει μαζί με τους ερευνητές που έχουν ενταχθεί τα
                        τελευταία χρόνια στο δυναμικό του σε μια συνάντηση «γνωριμίας», ανταλλαγής
                        ιδεών και δημιουργικότητας, την Παρασκευή 6 Μαΐου 2022, στις 14:00, στις
                        κεντρικές εγκαταστάσεις του ΕΚ «Αθηνά», στο Μαρούσι.
                        <br /> <br />
                        Καλωσορίζουμε τους νέους ερευνητές, γνωρίζουμε την επαγγελματική τους
                        πορεία, ανταλλάσσουμε μαζί τους απόψεις και βάζουμε τις βάσεις για
                        μελλοντικές συνεργασίες.
                        <br /> <br />
                        Οι ερευνητές συνομιλούν με τη Μαρία Γαβριηλίδου, τον Χρήστο Κουλαμά και τον
                        Θοδωρή Δαλαμάγκα. Η εκδήλωση συμπληρώνεται με επιλεγμένες μουσικές και
                        χαλαρή κουβέντα.
                    </p>
                </div>
                <div className="cmp__ev__g__right">
                    <CampaignTab />
                </div>
            </div>
        </div>
    );
};

export default CampaignEvent;
