import React from "react";
import styles from "./RCNav.module.css";
import { Link } from "react-router-dom";
import logowhite from "../../../../images/svgs/n-e_sima_sm_white.svg";
import arrleftwhite from "../../../../images/svgs/n-e_arrow-left-white.svg";
import arrrightwhite from "../../../../images/svgs/n-e_arrow-right-white.svg";

const RCNavFooter = (props) => {
    return (
        <nav className={`${styles["nav-rc"]} ${styles["nav-rc-footer"]}`}>
            <div>
                <a href="/">
                    <img
                        className={`${styles["nav-rc-logo-footer"]}`}
                        src={logowhite}
                        alt="Athena Logo 1"
                    />
                </a>
            </div>
            <div className={`${styles["nav-rc-right"]}`}>
                {props.prev_link.length !== 0 ? (
                    <Link to={props.prev_link}>
                        <img
                            className={`${styles["nav-rc-nav-img"]}`}
                            src={arrleftwhite}
                            alt="Left Arrow Blue SVG"
                        />
                    </Link>
                ) : (
                    <div style={{ width: "2rem" }}>&nbsp;</div>

                    // <img
                    //     className={`${styles["nav-rc-nav-img"]}`}
                    //     src={arrleftwhite}
                    //     alt="Left Arrow Blue SVG"
                    // />
                )}

                {props.next_link.length !== 0 ? (
                    <Link to={props.next_link}>
                        <img
                            className={`${styles["nav-rc-nav-img"]}`}
                            src={arrrightwhite}
                            alt="Right Arrow Blue SVG"
                        />
                    </Link>
                ) : (
                    <div style={{ width: "2rem" }}>&nbsp;</div>
                    // <img
                    //     className={`${styles["nav-rc-nav-img"]}`}
                    //     src={arrrightwhite}
                    //     alt="Right Arrow Blue SVG"
                    // />
                )}
            </div>
        </nav>
    );
};

export default RCNavFooter;
