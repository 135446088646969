import React from "react";
import styles from "./Header.module.css";
import "../../css/utilities.css";
import "../../css/typography.css";
import logo from "../../../../images/svgs/n-e_sima.svg";
import nesima from "../../../../images/pngs/hero-dots.png";

const Header = () => {
    return (
        <header className={`${styles.header}`}>
            <img className={`${styles["header--img-athena"]}`} src={logo} alt="Hero" />
            <div className={`${styles["header--dots-box"]}`}>
                <img className={`${styles["header--img-dots"]}`} src={nesima} alt="Hero dots" />
            </div>
            <p className={`header-text`}>
                <span>Νέες ερευνητικές ομάδες,</span>
                <span>νέες κατευθύνσεις</span>
            </p>
        </header>
    );
};

export default Header;
