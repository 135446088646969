import React from "react";
import styles from "./RCNav.module.css";
import "./RCNavHeader.css";
import { Link, NavLink } from "react-router-dom";

//Logos
import campaignlogo from "../../../../images/svgs/n-e_sima_sm.svg";
// import campaignlogo from "../../../../images/svgs/n-e_sima.svg";
// import athenalogo from "../../../../images/pngs/athena-logo.png";
import athenalogo from "../../../../images/svgs/athena-logo-header.svg";

// import arrleft from "../../../../images/svgs/n-e_arrow-left.svg";
// import arrright from "../../../../images/svgs/n-e_arrow-right.svg";

const RCNavHeader = (props) => {
    function clickToAboutInner() {
        // document.getElementById("about-link").classList.add("nav__active__link");
        props.clickToAbout();
    }

    function clickToReseachersInner() {
        // window.scrollTo(0, 2050);
        window.scrollTo(0, document.getElementById("reseachers").offsetTop);
    }

    function clickToCampaignInner() {}

    return (
        <nav className={`${styles["nav-rc"]} ${styles["nav-rc-header"]}`}>
            <div className={`${styles["left-nav-part"]}`}>
                <Link to="/" className={`${styles["campaign-logo"]}`}>
                    <svg
                        className={`${styles["nav-rc-logo-header"]}`}
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 946 386"
                        style={{ enableBackground: "new 0 0 946 386" }}>
                        <g>
                            <polygon
                                className={`${styles["st0"]}`}
                                points="341.4,198.6 341.4,332 62.5,332 62.5,301.7 35.4,311.7 35.4,359 368.4,359 368.4,188.6 	"
                            />
                            <polygon
                                className={`${styles["st0"]}`}
                                points="62.5,186.4 62.5,53 341.4,53 341.4,83.3 368.4,73.3 368.4,26 35.4,26 35.4,196.4 	"
                            />
                            <g>
                                <circle
                                    className={`${styles["st0"]}`}
                                    cx="206"
                                    cy="188.6"
                                    r="18.4"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="206"
                                    cy="131.7"
                                    r="9.5"
                                />
                                <circle className={`${styles["st1"]}`} cx="206" cy="93.4" r="9.5" />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="206"
                                    cy="283.9"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="110.8"
                                    cy="188.6"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="301.2"
                                    cy="188.6"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="123.5"
                                    cy="236.2"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="288.5"
                                    cy="141"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="158.4"
                                    cy="271.1"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="253.6"
                                    cy="106.1"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="253.6"
                                    cy="271.1"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="158.4"
                                    cy="106.1"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="288.5"
                                    cy="236.2"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="123.5"
                                    cy="141"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="206"
                                    cy="245.6"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="149.1"
                                    cy="188.6"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="262.9"
                                    cy="188.6"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="165.8"
                                    cy="228.9"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="246.3"
                                    cy="148.4"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="246.3"
                                    cy="228.9"
                                    r="9.5"
                                />
                                <circle
                                    className={`${styles["st1"]}`}
                                    cx="165.8"
                                    cy="148.4"
                                    r="9.5"
                                />
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M445.7,170.1v-37.8h21.5v4.9h-16v11.4h15.5v4.9h-15.5v11.8h16.5v4.9H445.7z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M500,170.1v-33h-21.2v33h-5.4v-37.8h32.1v37.8H500z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M513.9,170.1v-37.8h5.4v37.8H513.9z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M558.1,170.1L532.8,141v29.1h-5.1v-37.8h4.3l25.6,29.3v-29.3h5.1v37.8H558.1z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M610,151.2c0,5.3-1.8,9.8-5.4,13.5c-3.8,3.9-8.8,5.9-15,5.9c-6,0-10.9-1.9-14.8-5.9
                            c-3.6-3.7-5.4-8.3-5.4-13.5c0-5.4,1.8-9.9,5.5-13.6c3.8-3.8,8.8-5.8,14.8-5.8c6,0,11,1.9,14.9,5.8
                            C608.2,141.2,610,145.7,610,151.2z M604.4,151.2c0-4.2-1.4-7.6-4.2-10.3c-2.8-2.7-6.4-4.1-10.6-4.1c-4.2,0-7.7,1.4-10.5,4.1
                            c-2.8,2.7-4.2,6.2-4.2,10.3s1.4,7.6,4.2,10.3c2.8,2.7,6.4,4.1,10.5,4.1c4.2,0,7.7-1.4,10.5-4.1
                            C603.1,158.8,604.4,155.3,604.4,151.2z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M650.6,149.6c0,3.2-0.8,6.2-2.3,9c-1.6,2.9-3.7,5.2-6.3,6.9h8.6v4.5h-15.8v-3.9c3.2-1.3,5.7-3.6,7.7-7
                            c1.7-3.1,2.6-6.2,2.6-9.4c0-3.7-1.1-6.8-3.2-9.3c-2.3-2.7-5.3-4-9.1-4c-3.7,0-6.8,1.3-9,4c-2.1,2.5-3.2,5.6-3.2,9.3
                            c0,3.2,0.9,6.4,2.6,9.4c1.9,3.4,4.5,5.7,7.7,7v3.9h-15.8v-4.5h8.5c-2.6-1.7-4.7-4-6.3-6.9c-1.6-2.9-2.3-5.9-2.3-9
                            c0-5.1,1.7-9.4,5.1-12.8c3.5-3.4,7.7-5.1,12.7-5.1c5,0,9.3,1.7,12.7,5.1C648.9,140.2,650.6,144.4,650.6,149.6z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M687.7,170.1L662.4,141v29.1h-5.1v-37.8h4.3l25.6,29.3v-29.3h5.1v37.8H687.7z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M716,137.1v33h-5.4v-33h-13.1v-4.9h31.6v4.9H716z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M755.9,170.1l-5-11.2h-16.1l-4.8,11.2h-5.5l16.3-38.1h3.8l16.9,38.1H755.9z M742.6,140.3l-5.7,13.7h11.8
                            L742.6,140.3z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M762.9,170.1v-3.2l12.3-16.5l-11.5-15.2v-2.9h25.5v4.2h-19l10.9,13.9l-11.7,15.5h20.2v4.2H762.9z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M461,203.7v33h-5.4v-33h-13.1v-4.9h31.6v4.9H461z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M514.6,217.7c0,5.3-1.8,9.8-5.4,13.5c-3.8,3.9-8.8,5.9-15,5.9c-6,0-10.9-1.9-14.8-5.9
                            c-3.6-3.7-5.4-8.3-5.4-13.5c0-5.4,1.8-9.9,5.5-13.6c3.8-3.8,8.8-5.8,14.8-5.8c6,0,11,1.9,14.9,5.8
                            C512.7,207.7,514.6,212.3,514.6,217.7z M509,217.7c0-4.2-1.4-7.6-4.2-10.3c-2.8-2.7-6.4-4.1-10.6-4.1c-4.2,0-7.7,1.4-10.5,4.1
                            c-2.8,2.7-4.2,6.2-4.2,10.3s1.4,7.6,4.2,10.3c2.8,2.7,6.4,4.1,10.5,4.1c4.2,0,7.7-1.4,10.5-4.1C507.6,225.3,509,221.9,509,217.7z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M566.4,236.7v-29.5h-0.1l-11.7,14.6h-0.9l-11.7-14.6H542v29.5h-5.4v-37.8h5.1l12.5,15.5l12.6-15.5h5v37.8
                            H566.4z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M580,236.7v-37.8h21.5v4.9h-16v11.4h15.5v4.9h-15.5v11.8h16.5v4.9H580z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M635,236.7l-13.4-29.7l-12.5,29.7h-5.5l16.3-38.1h3.8l16.9,38.1H635z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M672,236.7l-13.4-29.7l-12.5,29.7h-5.5l16.3-38.1h3.8l16.9,38.1H672z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M718.5,217.7c0,5.3-1.8,9.8-5.4,13.5c-3.8,3.9-8.8,5.9-15,5.9c-6,0-10.9-1.9-14.8-5.9
                            c-3.6-3.7-5.4-8.3-5.4-13.5c0-5.4,1.8-9.9,5.5-13.6c3.8-3.8,8.8-5.8,14.8-5.8c6,0,11,1.9,14.9,5.8
                            C716.7,207.7,718.5,212.3,718.5,217.7z M712.9,217.7c0-4.2-1.4-7.6-4.2-10.3c-2.8-2.7-6.4-4.1-10.6-4.1c-4.2,0-7.7,1.4-10.5,4.1
                            c-2.8,2.7-4.2,6.2-4.2,10.3s1.4,7.6,4.2,10.3c2.8,2.7,6.4,4.1,10.5,4.1c4.2,0,7.7-1.4,10.5-4.1
                            C711.5,225.3,712.9,221.9,712.9,217.7z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M755.5,236.7l-25.3-29.1v29.1h-5.1v-37.8h4.3l25.6,29.3v-29.3h5.1v37.8H755.5z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M774.1,235.2c0,1.7-0.6,3.2-1.6,4.7c-1.1,1.6-2.7,2.7-4.9,3.3v-1.1c1.4-0.9,2.2-2.5,2.2-4.7
                            c0-0.5-0.1-0.9-0.2-1.4c-1.8-0.1-2.7-1.1-2.7-3.1c0-1.8,1.3-3.2,3.3-3.2c1.2,0,2.2,0.5,2.8,1.4
                            C773.7,232.3,774.1,233.6,774.1,235.2z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M820.2,236.7v-29.5h-0.1l-11.7,14.6h-0.9l-11.7-14.6h-0.1v29.5h-5.4v-37.8h5.1l12.5,15.5l12.6-15.5h5v37.8
                            H820.2z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M861.1,236.7l-5-11.2H840l-4.8,11.2h-5.5l16.3-38.1h3.8l16.9,38.1H861.1z M847.8,206.9l-5.7,13.7h11.8
                            L847.8,206.9z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M867.6,236.7l24.1-33H869v-4.9h32.6l-24.1,33h24.1v4.9H867.6z"
                                />
                                <path
                                    className={`${styles["st0"]}`}
                                    d="M906.7,236.7v-37.8h5.4v37.8H906.7z"
                                />
                            </g>
                        </g>
                    </svg>

                    {/*
                        <img
                            className={`${styles["nav-rc-logo-header"]}`}
                            src={campaignlogo}
                            alt="Athena Logo 1"
                        />
                    */}
                </Link>

                <div className={`${styles["divider"]}`}>&nbsp;</div>

                <NavLink
                    id="about-link"
                    to="/"
                    onClick={props.clickToAbout}
                    className={"campaign-btn " + props.aboutState}>
                    σχετικα
                </NavLink>
                <div className={`${styles["divider"]}`}>&nbsp;</div>
                <NavLink
                    id="researchers-link"
                    to={{ pathname: "/", hash: "#reseachers" }}
                    onClick={clickToReseachersInner}
                    className={"campaign-btn " + props.researchersState}>
                    οι ερευνητες
                </NavLink>
                <div className={`${styles["divider"]}`}>&nbsp;</div>
                <NavLink
                    id="campaign-link"
                    to="/campaign"
                    onClick={props.clickToCampaign}
                    className={"campaign-btn " + props.campaignState}>
                    η εκδηλωση
                </NavLink>

                {
                    // <a
                    //     id="about-link"
                    //     href="/"
                    //     onClick={clickToAboutInner}
                    //     className={"campaign-btn " + props.aboutState}>
                    //     σχετικα
                    // </a>
                    // <div className={`${styles["divider"]}`}>&nbsp;</div>
                    // <a
                    //     id="researchers-link"
                    //     href="/#reseachers"
                    //     onClick={clickToReseachersInner}
                    //     className="campaign-btn">
                    //     οι ερευνητες
                    // </a>
                    // <div className={`${styles["divider"]}`}>&nbsp;</div>
                    // <a
                    //     id="campaign-link"
                    //     href="/campaign"
                    //     onClick={clickToCampaignInner}
                    //     className="campaign-btn">
                    //     η εκδηλωση
                    // </a>
                }
            </div>
            <a className={`${styles["athena-logo"]}`} href="http://www.athenarc.gr/el">
                <img src={athenalogo} alt="athena"></img>
            </a>

            {
                // <div className={`${styles["nav-rc-right"]}`}>
                //     {props.prev_link.length !== 0 ? (
                //         <Link to={props.prev_link}>
                //             <img
                //                 className={`${styles["nav-rc-nav-img"]}`}
                //                 src={arrleft}
                //                 alt="Left Arrow Blue SVG"
                //             />
                //         </Link>
                //     ) : (
                //         <img
                //             className={`${styles["nav-rc-nav-img"]}`}
                //             src={arrleft}
                //             alt="Left Arrow Blue SVG"
                //         />
                //     )}
                //     {props.next_link.length !== 0 ? (
                //         <Link to={props.next_link}>
                //             <img
                //                 className={`${styles["nav-rc-nav-img"]}`}
                //                 src={arrright}
                //                 alt="Right Arrow Blue SVG"
                //             />
                //         </Link>
                //     ) : (
                //         <img
                //             className={`${styles["nav-rc-nav-img"]}`}
                //             src={arrright}
                //             alt="Right Arrow Blue SVG"
                //         />
                //     )}
                // </div>
            }
        </nav>
    );
};

export default RCNavHeader;
