import React from "react";
import "./CampaignFeatures.css";

// import calendarIco from "../../../../images/svgs/svgrepo/calendar.svg";

const CampaignFeatures = () => {
    return (
        <>
            <div className="cm__ft__g--wrapper--outer">
                <div className="cm__ft__g">
                    <div className="cm__ft__item--wrapper--outer">
                        <div className="cm__ft__item--wrapper--inner cm__ft__it--1">
                            <div className="cm__ft__item u-pb--2">
                                <svg
                                    className="cm__ft--ico"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                </svg>
                                <h2 className="cm__ft--num">17</h2>
                                <h5 className="cm__ft--desc">ερευνητές με διεθνή εμπειρία</h5>
                            </div>
                        </div>
                    </div>
                    <div className="cm__ft__item--wrapper--outer">
                        <div className="cm__ft__item--wrapper--inner cm__ft__it--2">
                            <div
                                className="cm__ft__item"
                                style={{ width: "60%", paddingBottom: "0.5rem" }}>
                                <h2 className="cm__ft--h--index">H-index</h2>
                                <h2 className="cm__ft--num" style={{ marginTop: "2rem" }}>
                                    43
                                </h2>
                                <h5 className="cm__ft--desc">
                                    ανώτερος δείκτης δημοσιεύσεων (μέσος όρος 19)
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="cm__ft__item--wrapper--outer">
                        <div className="cm__ft__item--wrapper--inner cm__ft__it--3">
                            <div className="cm__ft__item cm__ft__item--pub u-pb--2">
                                <svg
                                    className="cm__ft--ico"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 512 512">
                                    <g>
                                        <g>
                                            <path d="M443.103,71.306H416.76V44.963c0-5.142-4.167-9.31-9.31-9.31h-26.342V9.31c0-5.142-4.167-9.31-9.31-9.31h-302.9    c-5.143,0-9.31,4.169-9.31,9.31v422.074c0,5.142,4.167,9.31,9.31,9.31H95.24v26.342c0,5.142,4.167,9.31,9.31,9.31h26.342v26.342    c0,5.142,4.167,9.31,9.31,9.31h302.9c5.143,0,9.31-4.169,9.31-9.31V80.616C452.413,75.474,448.246,71.306,443.103,71.306z     M78.208,422.074V18.621h284.279v403.453H78.208z M113.862,457.726v-17.032h257.935c5.143,0,9.31-4.169,9.31-9.31V54.274h17.033    v403.453H113.862z M433.792,493.379H149.513v-17.032H407.45c5.143,0,9.31-4.169,9.31-9.31V89.927h17.032V493.379z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M271.292,95.592H169.404c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h101.887c5.143,0,9.31-4.169,9.31-9.31    S276.435,95.592,271.292,95.592z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M313.448,141.771h-186.2c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h186.2c5.143,0,9.31-4.169,9.31-9.31    S318.59,141.771,313.448,141.771z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M313.448,187.948h-186.2c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h186.2c5.143,0,9.31-4.169,9.31-9.31    S318.59,187.948,313.448,187.948z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M313.448,234.125h-186.2c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h186.2c5.143,0,9.31-4.169,9.31-9.31    S318.59,234.125,313.448,234.125z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M313.448,280.303h-186.2c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h186.2c5.143,0,9.31-4.169,9.31-9.31    S318.59,280.303,313.448,280.303z" />
                                        </g>
                                    </g>
                                    <g>
                                        <g>
                                            <path d="M313.448,326.48h-186.2c-5.143,0-9.31,4.169-9.31,9.31s4.167,9.31,9.31,9.31h186.2c5.143,0,9.31-4.169,9.31-9.31    S318.59,326.48,313.448,326.48z" />
                                        </g>
                                    </g>
                                </svg>

                                <div className="cm__ft--numplus--wrapper">
                                    <span className="cm__ft--num">1500</span>
                                    <svg
                                        className="cm__ft--plus"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 349.03 349.031"
                                        style={{ enableBackground: "new 0 0 349.03 349.031" }}>
                                        <g>
                                            <path d="M349.03,141.226v66.579c0,5.012-4.061,9.079-9.079,9.079H216.884v123.067c0,5.019-4.067,9.079-9.079,9.079h-66.579   c-5.009,0-9.079-4.061-9.079-9.079V216.884H9.079c-5.016,0-9.079-4.067-9.079-9.079v-66.579c0-5.013,4.063-9.079,9.079-9.079   h123.068V9.079c0-5.018,4.069-9.079,9.079-9.079h66.579c5.012,0,9.079,4.061,9.079,9.079v123.068h123.067   C344.97,132.147,349.03,136.213,349.03,141.226z" />
                                        </g>
                                    </svg>
                                </div>
                                <h5 className="cm__ft--desc u-mg-bottom-sm">δημοσιεύσεις</h5>
                            </div>
                        </div>
                    </div>
                    <div className="cm__ft__item--wrapper--outer">
                        <div className="cm__ft__item--wrapper--inner cm__ft__it--4">
                            <div className="cm__ft__item cm__ft__item--pub">
                                <svg
                                    className="cm__ft--ico"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 297 297"
                                    style={{ enableBackground: "new 0 0 297 297" }}>
                                    <path d="M262.655,11.202h-39.041V9.884c0-5.458-4.425-9.884-9.883-9.884H83.269c-5.458,0-9.884,4.426-9.884,9.884v1.318h-39.04  c-5.458,0-9.883,4.425-9.883,9.884v54.029c0,37.247,20.16,58.044,56.813,58.772c3.445,8.52,7.957,16.27,13.484,22.951  c7.759,9.378,17.148,16.395,27.55,20.764v50.708h-39.04c-5.458,0-9.884,4.425-9.884,9.884v48.923c0,5.458,4.426,9.884,9.884,9.884  h130.463c5.458,0,9.883-4.426,9.883-9.884v-48.923c0-5.459-4.425-9.884-9.883-9.884h-39.04v-50.708  c10.401-4.369,19.791-11.386,27.55-20.764c5.527-6.681,10.039-14.432,13.484-22.951c36.652-0.728,56.812-21.524,56.812-58.772  V21.086C272.538,15.627,268.113,11.202,262.655,11.202z M44.229,75.115V30.969h29.156v60.454c0,7.696,0.703,15.179,2.045,22.34  C53.932,111.517,44.229,99.341,44.229,75.115z M203.848,248.076v29.156H93.152v-29.156H203.848z M154.925,228.31h-12.85v-45.787  c2.123,0.199,4.262,0.323,6.425,0.323s4.302-0.124,6.425-0.323V228.31z M148.5,163.078c-32.07,0-55.348-30.135-55.348-71.655V19.768  h110.695v71.655C203.848,132.943,180.57,163.078,148.5,163.078z M252.771,75.115c0,24.226-9.703,36.401-31.201,38.648  c1.342-7.161,2.044-14.644,2.044-22.34V30.969h29.157V75.115z" />
                                </svg>

                                <div className="cm__ft--numplus--wrapper">
                                    <span className="cm__ft--num">15</span>
                                    <svg
                                        className="cm__ft--plus"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 349.03 349.031"
                                        style={{ enableBackground: "new 0 0 349.03 349.031" }}>
                                        <g>
                                            <path d="M349.03,141.226v66.579c0,5.012-4.061,9.079-9.079,9.079H216.884v123.067c0,5.019-4.067,9.079-9.079,9.079h-66.579   c-5.009,0-9.079-4.061-9.079-9.079V216.884H9.079c-5.016,0-9.079-4.067-9.079-9.079v-66.579c0-5.013,4.063-9.079,9.079-9.079   h123.068V9.079c0-5.018,4.069-9.079,9.079-9.079h66.579c5.012,0,9.079,4.061,9.079,9.079v123.068h123.067   C344.97,132.147,349.03,136.213,349.03,141.226z" />
                                        </g>
                                    </svg>
                                </div>
                                <h5 className="cm__ft--desc u-mg-bottom-sm">
                                    διεθνή βραβεία και διακρίσεις
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cm__ft__g--wrapper--outer">
                <div className="cm__ft__g">
                    <div className="cm__ft__item--wrapper--outer">
                        <div className="cm__ft__item--wrapper--inner cm__ft__it--5">
                            <div className="cm__ft__item">
                                <svg
                                    className="cm__ft--ico u-h--5"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 32 32"
                                    style={{ enableBackground: "new 0 0 32 32" }}>
                                    <style type="text/css"></style>
                                    <path
                                        className="st0"
                                        d="M25,7c0-1.7,0.9-3,2-3s2,1.3,2,3H25v18"
                                    />
                                    <path className="st0" d="M11,4C9.9,4,9,5.3,9,7" />
                                    <line className="st0" x1="27" y1="4" x2="11" y2="4" />
                                    <path
                                        className="st0"
                                        d="M23,22c-1.1,0-2,1.3-2,3s0.9,3,2,3s2-1.3,2-3"
                                    />
                                    <path className="st0" d="M7,22c-1.1,0-2,1.3-2,3s0.9,3,2,3" />
                                    <line className="st0" x1="7" y1="28" x2="23" y2="28" />
                                    <line className="st0" x1="23" y1="22" x2="7" y2="22" />
                                    <line className="st0" x1="9" y1="22" x2="9" y2="7" />
                                    <line className="st0" x1="12" y1="11" x2="18" y2="11" />
                                    <line className="st0" x1="12" y1="14" x2="15" y2="14" />
                                </svg>

                                <h2 className="cm__ft--num">55</h2>
                                <h5 className="cm__ft--desc">διπλώματα ευρεσιτεχνίας</h5>
                            </div>
                        </div>
                    </div>
                    <div className="cm__ft__item--wrapper--outer">
                        <div className="cm__ft__item--wrapper--inner cm__ft__it--6">
                            <div className="cm__ft__item" style={{ width: "80%" }}>
                                <svg
                                    className="cm__ft--ico"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 24 24"
                                    style={{ enableBackground: "new 0 0 24 24" }}>
                                    <metadata>
                                        <sfw xmlns="http://ns.adobe.com/SaveForWeb/1.0/">
                                            <slices />
                                            <sliceSourceBounds
                                                width="505"
                                                height="984"
                                                bottomleftorigin="true"
                                                x="0"
                                                y="-552"
                                            />
                                        </sfw>
                                    </metadata>
                                    <g>
                                        <g>
                                            <g>
                                                <path d="M12,10c-0.1,0-0.2,0-0.3-0.1l-11-4C0.3,5.8,0,5.4,0,5s0.3-0.8,0.7-0.9l11-4c0.2-0.1,0.5-0.1,0.7,0l11,4     C23.7,4.2,24,4.6,24,5s-0.3,0.8-0.7,0.9l-11,4C12.2,10,12.1,10,12,10z M3.9,5L12,7.9L20.1,5L12,2.1L3.9,5z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M12,24c-0.1,0-0.2,0-0.3-0.1l-11-4c-0.5-0.2-0.8-0.8-0.6-1.3c0.2-0.5,0.8-0.8,1.3-0.6L12,21.9l10.7-3.9     c0.5-0.2,1.1,0.1,1.3,0.6c0.2,0.5-0.1,1.1-0.6,1.3l-11,4C12.2,24,12.1,24,12,24z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M12,24c-0.6,0-1-0.4-1-1V9c0-0.6,0.4-1,1-1s1,0.4,1,1v14C13,23.6,12.6,24,12,24z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M23,20c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1s1,0.4,1,1v14C24,19.6,23.6,20,23,20z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M1,20c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1s1,0.4,1,1v14C2,19.6,1.6,20,1,20z" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>

                                <h2 className="cm__ft--num">23</h2>
                                <h5 className="cm__ft--desc">&nbsp; έργα</h5>
                                <h5 className="cm__ft--desc">έρευνας & ανάπτυξης</h5>
                            </div>
                        </div>
                    </div>
                    <div className="cm__ft__item--wrapper--outer">
                        <div className="cm__ft__item--wrapper--inner cm__ft__it--7">
                            <div
                                className="cm__ft__item cm__ft__item--pub"
                                style={{ paddingTop: "2rem" }}>
                                <svg
                                    className="cm__ft--ico"
                                    viewBox="0 0 1024 1024"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372zm117.7-588.6c-15.9-3.5-34.4-5.4-55.3-5.4-106.7 0-178.9 55.7-198.6 149.9H344c-4.4 0-8 3.6-8 8v27.2c0 4.4 3.6 8 8 8h26.4c-.3 4.1-.3 8.4-.3 12.8v36.9H344c-4.4 0-8 3.6-8 8V568c0 4.4 3.6 8 8 8h30.2c17.2 99.2 90.4 158 200.2 158 20.9 0 39.4-1.7 55.3-5.1 3.7-.8 6.4-4 6.4-7.8v-42.8c0-5-4.6-8.8-9.5-7.8-14.7 2.8-31.9 4.1-51.8 4.1-68.5 0-114.5-36.6-129.8-98.6h130.6c4.4 0 8-3.6 8-8v-27.2c0-4.4-3.6-8-8-8H439.2v-36c0-4.7 0-9.4.3-13.8h135.9c4.4 0 8-3.6 8-8v-27.2c0-4.4-3.6-8-8-8H447.1c17.2-56.9 62.3-90.4 127.6-90.4 19.9 0 37.1 1.5 51.7 4.4a8 8 0 0 0 9.6-7.8v-42.8c0-3.8-2.6-7-6.3-7.8z" />
                                </svg>

                                <div className="cm__ft--numplus--wrapper">
                                    {
                                        // <svg
                                        //     className="cm__ft--plus"
                                        //     viewBox="-64 0 512 512"
                                        //     xmlns="http://www.w3.org/2000/svg">
                                        //     <path d="M365.46 357.74L147.04 255.89l218.47-101.88c16.02-7.47 22.95-26.51 15.48-42.53l-13.52-29C360 66.46 340.96 59.53 324.94 67L18.48 209.91a32.014 32.014 0 0 0-18.48 29v34.24c0 12.44 7.21 23.75 18.48 29l306.31 142.83c16.06 7.49 35.15.54 42.64-15.52l13.56-29.08c7.49-16.06.54-35.15-15.53-42.64z" />
                                        // </svg>
                                    }

                                    <svg
                                        className="cm__ft--plus"
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 512 512"
                                        style={{
                                            EnableBackground: "new 0 0 512 512",
                                            marginLeft: "-2rem"
                                        }}>
                                        <g>
                                            <g>
                                                <path
                                                    d="M491.841,156.427c-19.471-45.946-51.936-85.013-92.786-112.637C358.217,16.166,308.893-0.007,256,0    c-35.254-0.002-68.946,7.18-99.571,20.158C110.484,39.63,71.416,72.093,43.791,112.943C16.167,153.779-0.007,203.104,0,256    c-0.002,35.255,7.181,68.948,20.159,99.573c19.471,45.946,51.937,85.013,92.786,112.637C153.783,495.834,203.107,512.007,256,512    c35.253,0.002,68.946-7.18,99.571-20.158c45.945-19.471,85.013-51.935,112.638-92.785C495.834,358.22,512.007,308.894,512,256    C512.002,220.744,504.819,187.052,491.841,156.427z M460.413,342.257c-16.851,39.781-45.045,73.724-80.476,97.677    c-35.443,23.953-78.02,37.926-123.936,37.933c-30.619-0.002-59.729-6.218-86.255-17.454    c-39.781-16.85-73.724-45.044-97.677-80.475C48.114,344.495,34.14,301.917,34.133,256c0.002-30.62,6.219-59.731,17.454-86.257    c16.851-39.781,45.045-73.724,80.476-97.677C167.506,48.112,210.084,34.139,256,34.132c30.619,0.002,59.729,6.218,86.255,17.454    c39.781,16.85,73.724,45.044,97.677,80.475c23.953,35.443,37.927,78.02,37.934,123.939    C477.864,286.62,471.648,315.731,460.413,342.257z"
                                                    style={{ fill: "transparent" }}
                                                />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M406.292,200.337c0.001-9.224-7.476-16.7-16.698-16.7c-3.256,0-6.553,0.997-9.263,2.804    c-17.432,11.619-37.463,17.416-57.535,17.42c-20.07-0.003-40.103-5.801-57.534-17.42c-0.003-0.002-0.008-0.004-0.012-0.007    c-23.015-15.342-49.551-23.026-76.049-23.022c-26.502-0.003-53.042,7.682-76.061,23.029l0.001,0.002    c-4.483,2.995-7.437,8.097-7.437,13.893c0,9.222,7.477,16.699,16.699,16.699c3.256,0,6.554-0.999,9.264-2.805    c17.431-11.619,37.463-17.416,57.533-17.42c20.07,0.003,40.103,5.801,57.534,17.42c23.018,15.347,49.558,23.033,76.06,23.029    c26.502,0.003,53.042-7.683,76.06-23.029l-0.001-0.001C403.338,211.235,406.292,206.133,406.292,200.337z" />
                                            </g>
                                        </g>
                                        <g>
                                            <g>
                                                <path d="M406.292,300.718c0.001-9.225-7.476-16.7-16.698-16.7c-3.256,0-6.553,0.997-9.263,2.804    c-17.432,11.619-37.463,17.416-57.535,17.42c-20.07-0.003-40.103-5.801-57.534-17.42c-0.003-0.002-0.008-0.004-0.012-0.007    c-23.015-15.342-49.551-23.026-76.049-23.022c-26.502-0.003-53.042,7.683-76.06,23.029l0.001,0.002    c-4.483,2.995-7.438,8.097-7.438,13.893c0,9.222,7.477,16.699,16.699,16.699c3.256,0,6.553-0.999,9.264-2.804    c17.432-11.619,37.463-17.417,57.533-17.421c20.07,0.003,40.103,5.801,57.534,17.42c23.018,15.347,49.558,23.033,76.06,23.029    c26.502,0.003,53.042-7.683,76.06-23.029l-0.001-0.001C403.338,311.616,406.292,306.514,406.292,300.718z" />
                                            </g>
                                        </g>
                                    </svg>

                                    <span className="cm__ft--num">5,5</span>
                                    <span
                                        className="cm__ft--desc"
                                        style={{ marginLeft: "5px" }}></span>
                                </div>
                                <h5 className="cm__ft--desc u-mg-bottom-sm">εκατ. ευρώ</h5>
                            </div>
                        </div>
                    </div>
                    <div className="cm__ft__item--wrapper--outer">
                        <div className="cm__ft__item--wrapper--inner cm__ft__it--8">
                            <div className="cm__ft__item">
                                <svg
                                    className="cm__ft--ico"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 380 380"
                                    style={{ enableBackground: "new 0 0 380 380" }}>
                                    <g>
                                        <path d="M313.071,123.073C313.071,55.21,257.861,0,190,0S66.929,55.21,66.929,123.073c0,46.066,25.899,88.288,66.512,109.331v10.91   c-5.674,4.78-9.104,11.901-9.104,19.52c0,5.836,1.974,11.218,5.283,15.522c-3.309,4.304-5.283,9.686-5.283,15.523   c0,5.836,1.974,11.217,5.283,15.521c-3.309,4.304-5.283,9.686-5.283,15.522c0,13.183,10.048,24.062,22.887,25.386   C151.616,367.362,167.126,380,185.532,380h8.936c18.406,0,33.916-12.638,38.309-29.691c12.839-1.324,22.887-12.203,22.887-25.386   c0-5.836-1.974-11.217-5.283-15.522c3.309-4.304,5.283-9.686,5.283-15.521c0-5.837-1.974-11.219-5.283-15.523   c3.309-4.304,5.283-9.686,5.283-15.522c0-7.62-3.431-14.74-9.104-19.52v-10.91C287.172,211.361,313.071,169.14,313.071,123.073z    M194.468,360h-8.936c-7.126,0-13.355-3.844-16.773-9.556h42.482C207.823,356.156,201.594,360,194.468,360z M232.417,257.815   c1.973,0.898,3.247,2.868,3.247,5.02c0,3.044-2.478,5.521-5.523,5.521h-80.281c-3.046,0-5.523-2.477-5.523-5.521   c0-2.151,1.274-4.122,3.247-5.02c3.567-1.624,5.857-5.182,5.857-9.102v-22.57c0-3.928-2.3-7.492-5.878-9.111   c-36.834-16.665-60.634-53.546-60.634-93.96C86.929,66.238,133.166,20,190,20s103.071,46.238,103.071,103.073   c0,40.414-23.8,77.295-60.634,93.96c-3.578,1.619-5.878,5.183-5.878,9.111v22.57C226.56,252.633,228.85,256.192,232.417,257.815z" />
                                        <path d="M218.87,130.293l-10.824-6.558l12.741-59.903c0.457-2.147-0.703-4.312-2.744-5.12c-2.044-0.809-4.369-0.025-5.506,1.852   l-42.924,70.858c-1.288,2.126-0.608,4.893,1.517,6.18l10.824,6.558l-12.739,59.891c-0.457,2.148,0.703,4.312,2.744,5.12   c0.541,0.214,1.102,0.317,1.655,0.317c1.536,0,3.015-0.789,3.85-2.169l42.922-70.847   C221.675,134.348,220.995,131.581,218.87,130.293z" />
                                    </g>
                                </svg>

                                <h2 className="cm__ft--num">5</h2>
                                <h5 className="cm__ft--desc">επιχειρηματικές προσπάθειες </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CampaignFeatures;
