import React from "react";
import styles from "./RCBlog.module.css";
import "../../css/utilities.css";

import RCBlogParComp from "./RCBlogParComp";

const RCBlog = (props) => {
    return (
        <div className={`${styles.grid} u-padding-sm`}>
            {props.rcQA.map((elementQA, i, array) => {
                return (
                    <RCBlogParComp
                        text_heading={elementQA["Q"]}
                        text_body={elementQA["A"]}
                        className={i < array.length - 1 ? "item--" + (i + 1) : "item--right-col"}
                        video_src={props.video_src}
                        links={props.links}
                        links2={props.links2}
                        key={props.path + "-" + i}
                    />
                );
            })}
        </div>
    );
};

export default RCBlog;
