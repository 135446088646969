import React from "react";
import "./CampaignHeader2.css";

import logo from "../../../../images/svgs/n-e_sima_2.svg";
import nesima from "../../../../images/pngs/hero-dots.png";

const CampaignHeader2 = () => {
    return (
        <div className="cmh2--header">
            <div className="cmh2--hg">
                <div className="cmh2--hg--left--inner">
                    <h1 className="cmh2-h-epinoontas">
                        Επινοωντας
                        <br />
                        το μελλον, μαζι
                    </h1>
                    <p className="cmh2-p-nees">
                        Νέες ερευνητικές ομάδες,
                        <br />
                        νέες κατευθύνσεις
                    </p>
                </div>
                {/*
                <div className="cmh2--hg--left">
                    <img className="cmh2--campaign-img--hidden" src={logo} alt="campaign"></img>
                </div>
                */}
                <svg
                    className="cmh2--campaign-img"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 400 386"
                    style={{ enableBackground: "new 0 0 400 386" }}>
                    <g>
                        <polygon
                            className="cmph2--st0"
                            points="341.4,198.6 341.4,332 62.5,332 62.5,301.7 35.4,311.7 35.4,359 368.4,359 368.4,188.6 	"
                        />
                        <polygon
                            className="cmph2--st0"
                            points="62.5,186.4 62.5,53 341.4,53 341.4,83.3 368.4,73.3 368.4,26 35.4,26 35.4,196.4 	"
                        />
                        <g>
                            <circle className="cmph2--st0" cx="206" cy="188.6" r="18.4" />
                            <circle className="cmph2--st1" cx="206" cy="131.7" r="9.5" />
                            <circle className="cmph2--st1" cx="206" cy="93.4" r="9.5" />
                            <circle className="cmph2--st1" cx="206" cy="283.9" r="9.5" />
                            <circle className="cmph2--st1" cx="110.8" cy="188.6" r="9.5" />
                            <circle className="cmph2--st1" cx="301.2" cy="188.6" r="9.5" />
                            <circle className="cmph2--st1" cx="123.5" cy="236.2" r="9.5" />
                            <circle className="cmph2--st1" cx="288.5" cy="141" r="9.5" />
                            <circle className="cmph2--st1" cx="158.4" cy="271.1" r="9.5" />
                            <circle className="cmph2--st1" cx="253.6" cy="106.1" r="9.5" />
                            <circle className="cmph2--st1" cx="253.6" cy="271.1" r="9.5" />
                            <circle className="cmph2--st1" cx="158.4" cy="106.1" r="9.5" />
                            <circle className="cmph2--st1" cx="288.5" cy="236.2" r="9.5" />
                            <circle className="cmph2--st1" cx="123.5" cy="141" r="9.5" />
                            <circle className="cmph2--st1" cx="206" cy="245.6" r="9.5" />
                            <circle className="cmph2--st1" cx="149.1" cy="188.6" r="9.5" />
                            <circle className="cmph2--st1" cx="262.9" cy="188.6" r="9.5" />
                            <circle className="cmph2--st1" cx="165.8" cy="228.9" r="9.5" />
                            <circle className="cmph2--st1" cx="246.3" cy="148.4" r="9.5" />
                            <circle className="cmph2--st1" cx="246.3" cy="228.9" r="9.5" />
                            <circle className="cmph2--st1" cx="165.8" cy="148.4" r="9.5" />
                        </g>
                    </g>
                </svg>
                <img className="cmh2--dots-img" src={nesima} alt="hero dots"></img>
                {/* <img className="cmh2--campaign-img" src={logo} alt="campaign"></img> */}
                {/* 
                    <div className="cmh2--hg--right"></div>
                */}
            </div>
        </div>
    );
};

export default CampaignHeader2;
