import React from "react";
// import "./App.css";
import Home from "./react-components/pages/home/Home";
// import RC from "./react-components/pages/rc/RC";
// import { Link } from "react-router-dom";
import ScrollToTop from "./react-components/pages/general/scrollToTop";

function App(props) {
    return (
        <>
            {
                // <ScrollToTop />
            }
            <Home researchers={props.researchers} />
        </>
    );
}

export default App;
