import React, { useState } from "react";
import "../css/base.css";
import "../css/utilities.css";
import "./Campaign.css";

import CampaignHeader from "./campaigbn-header/CampaignHeader";
import RCNavHeader from "../rc/rcNav/RCNavHeader";
import CampaignHeader2 from "./campaign-header-2/CampaignHeader2";
import CampaignWhen from "./campaign-when/CampaignWhen";
import CampaignEvent from "./campaign-event/CampaignEvent";
import CampaignFeatures from "./campaign-features/CampaignFeatures";
import CampaignFooter1 from "./campaign-footer-1/CampaignFooter1";
import Footer from "../general/footer/Footer";

const Campaign = () => {
    const [aboutState, aboutAct] = useState("");
    const [researchersState, researchersAct] = useState("");
    const [campaignState, campaignAct] = useState("");

    function clickToAbout() {
        researchersAct("");
        campaignAct("");

        aboutAct((oldState) => {
            return "nav__active__link";
        });
    }

    function clickToReseachers(temp) {
        aboutAct("");
        campaignAct("");

        researchersAct("nav__active__link");
    }

    function clickToCampaign() {
        researchersAct("");
        aboutAct("");

        campaignAct((oldState) => {
            return "nav__active__link";
        });
    }

    return (
        <div className="u-bg-img-wrap-outer">
            <div className="u-bg-img-wrap-inner">
                {
                    // <CampaignHeader />
                }
                <RCNavHeader
                    clickToAbout={clickToAbout}
                    clickToCampaign={clickToCampaign}
                    clickToReseachers={clickToReseachers}
                    aboutState={aboutState}
                    researchersState={researchersState}
                    campaignState={campaignState}
                />
                <CampaignHeader2 />
                <CampaignWhen />
                <CampaignEvent />
                <CampaignFeatures />
                <CampaignFooter1 />
                <Footer />
            </div>
        </div>
    );
};

export default Campaign;
