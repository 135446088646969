import React from "react";
import styles from "./Footer.module.css";
import athenalogo from "../../../../images/pngs/athena-logo.png";
import footerdots from "../../../../images/pngs/footer-dots.png";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={`${styles["left-div-footer"]}`}>
                <img
                    className={`${styles["logo-athena"]}`}
                    src={athenalogo}
                    alt="Athena footer logo"
                />
            </div>
            <div className={`${styles["right-div-footer"]}`}>
                <img
                    className={`${styles["footer-dots"]}`}
                    src={footerdots}
                    alt="Athena footer dots"
                />
            </div>
        </footer>
    );
};

export default Footer;
