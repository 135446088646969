import React from "react";
import "./RCHero.css";
import herodots from "../../../../images/pngs/hero-dots.png";

const RCHero = (props) => {
    return (
        <section className={`${props.className} section-hero-rc`}>
            <div className="section-hero-rc-grid">
                <div className="section-hero-rc-left">
                    <div className="section-hero-rc-text-box">
                        {
                            // <span className="section-hero-rc-text-box-helpery">{props.rcMoto}</span>
                        }
                        <blockquote className="section-hero-rc-text-box-primary">
                            {props.rcQuote}
                        </blockquote>
                    </div>
                    <img
                        className="section-hero-rc-dots-img"
                        src={herodots}
                        alt="Athena Hero Dots"
                    />
                </div>
                <div className="section-hero-rc-right">
                    <img className="section-hero-rc-img" src={props.rc_img} alt="Researcher" />
                </div>
            </div>
            <div className="section-hero-rc-red-box">
                <span className="section-hero-rc-red-box-primary">{props.rcTxtPrimary}</span>
                <span className="section-hero-rc-red-box-secondary">{props.rcTxtSecondary}</span>
                <span className="section-hero-rc-red-box-tertiary">{props.rcTxtInstTitle}</span>
            </div>
        </section>
    );
};

export default RCHero;
