import React from "react";
import "./SectionResearcher.css";

const sectionResearcher = (props) => {
    return (
        <section id="reseachers" className="section-researchers">
            {props.children}
        </section>
    );
};

export default sectionResearcher;
