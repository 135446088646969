import React, { useState } from "react";
import "../css/base.css";
import "../css/utilities.css";
import "./home.css";

import PreHeader from "../general/preHeader/PreHeader";
import Header from "./header/Header";
import SectionAbout from "./section-about/SectionAbout";
import SectionResearcher from "./section-researcher/SectionResearcher";
import Grid from "./grid/Grid";
import Researcher from "./researcher/Researcher";
import Footer from "../general/footer/Footer";
import "./home.css";

import RCNavHeader from "../rc/rcNav/RCNavHeader";
import CampaignFeatures from "../campaign/campaign-features/CampaignFeatures";

import presidentImg from "../../../images/researchers/photos_624x771/emiris.jpg";

const Home = (props) => {
    const [aboutState, aboutAct] = useState("");
    const [researchersState, researchersAct] = useState("");
    const [campaignState, campaignAct] = useState("");

    function clickToAbout() {
        researchersAct("");
        campaignAct("");

        aboutAct("nav__active__link");

        // document.getElementById("researchers-link").classList.remove("active");
        // document.getElementById("about-link").classList.add("active");
    }

    function clickToCampaign() {
        researchersAct("");
        aboutAct("");
        campaignAct("nav__active__link");
    }

    function clickToReseachers(temp) {
        aboutAct("");
        campaignAct("");

        researchersAct((oldState) => {
            return "nav__active__link";
        });

        // window.scrollTo(0, document.getElementById("reseachers").offsetTop);

        // console.log("clickToReseachers");
        // window.scrollTo(0, 2050);
        // document.getElementById("about-link").classList.remove("active");
        // document.getElementById("researchers-link").classList.add("active");
    }

    return (
        <div className="u-bg-img-wrap-outer">
            <div className="u-bg-img-wrap-inner">
                <RCNavHeader
                    clickToAbout={clickToAbout}
                    clickToCampaign={clickToCampaign}
                    clickToReseachers={clickToReseachers}
                    aboutState={aboutState}
                    researchersState={researchersState}
                    campaignState={campaignState}
                />
                {
                    // <PreHeader />
                }
                <Header />
                <main>
                    <section className="section-home-pre-gallery" style={{ marginBottom: "10rem" }}>
                        <h3 className="heading-tertiaty">
                            Επινοωντας το μελλον μαζι, Νεες ερευνητικες ομαδες, νεες κατευθυνσεις
                        </h3>
                        <p className="future-txt">
                            Επινοούμε το μέλλον μαζί. Δίνουμε χώρο στις νέες ιδέες, και στο άριστο
                            ανθρώπινο δυναμικό και επενδύουμε στους καλύτερους. Το Ερευνητικό Κέντρο
                            «Αθηνά" εμπλουτίζεται, επεκτείνεται σε νέες κατευθύνσεις και ερευνητικά
                            πεδία, αποκτά ανανεωμένη δυναμική.
                            <br /> <br /> Καλωσορίζουμε τους 17 ερευνητές που έχουν ενταχθεί τα
                            τελευταία χρόνια στο δυναμικό του ΕΚ «Αθηνά», παρουσιάζουμε την
                            επιστημονική τους πορεία και μαθαίνουμε τους επόμενους στόχους τους.
                            Συζητάμε για νέες συνεργασίες και στοχεύουμε στη γόνιμη δικτύωση των
                            ερευνητικών ομάδων εντός του Κέντρου.
                        </p>
                    </section>
                    <CampaignFeatures />
                    <SectionResearcher>
                        <Grid>
                            {props.researchers.map((researcher) => {
                                return (
                                    <Researcher
                                        rc_img={researcher.rc_img_sm}
                                        rcTxtPrimary={researcher.rcTxtPrimary}
                                        rcTxtSecondary={researcher.rcTxtSecondary}
                                        alt={researcher.rcTxtPrimary}
                                        key={researcher.path + "-rch"}
                                        path={researcher.path}
                                    />
                                );
                            })}
                        </Grid>
                    </SectionResearcher>
                    <section className="section-president">
                        <div className="president-grid">
                            <div>
                                <img
                                    className="president-img"
                                    src={presidentImg}
                                    alt="President of 'Athena' RC"></img>
                            </div>
                            <blockquote className="president-quote">
                                Καλωσορίζω και συγχαίρω τους νέους ερευνητές μας, και περιμένω να
                                επινοήσουμε μαζί το μέλλον, καθώς είμαστε όλοι νέα μέλη της
                                οικογένειας του "Αθηνά". Οι νέες ιδέες θα έχουν αρκετό χώρο για να
                                αναπτυχθούν με βάση το ανθρώπινο δυναμικό μας. Αυτή είναι η
                                κινητήρια δύναμή μας και η προοπτική για μια ακόμη πιο δυναμική
                                πορεία.
                            </blockquote>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </div>
    );
};

export default Home;
