import React from "react";
import "./CampaignWhen.css";

// import calendarIco from "../../../../images/svgs/svgrepo/calendar.svg";

const CampaignWhen = () => {
    return (
        <div className="cm_wh__g">
            <div className="cm_wh__item--wrapper u-border--right">
                <div className="cm_wh__item">
                    <svg
                        className="cm-ico"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 60 60"
                        style={{ enableBackground: "new 0 0 60 60" }}>
                        <g>
                            <path d="M57,4h-7V1c0-0.553-0.447-1-1-1h-7c-0.553,0-1,0.447-1,1v3H19V1c0-0.553-0.447-1-1-1h-7c-0.553,0-1,0.447-1,1v3H3   C2.447,4,2,4.447,2,5v11v43c0,0.553,0.447,1,1,1h54c0.553,0,1-0.447,1-1V16V5C58,4.447,57.553,4,57,4z M43,2h5v3v3h-5V5V2z M12,2h5   v3v3h-5V5V2z M4,6h6v3c0,0.553,0.447,1,1,1h7c0.553,0,1-0.447,1-1V6h22v3c0,0.553,0.447,1,1,1h7c0.553,0,1-0.447,1-1V6h6v9H4V6z    M4,58V17h52v41H4z" />
                            <path d="M38,23h-7h-2h-7h-2h-9v9v2v7v2v9h9h2h7h2h7h2h9v-9v-2v-7v-2v-9h-9H38z M31,25h7v7h-7V25z M38,41h-7v-7h7V41z M22,34h7v7h-7   V34z M22,25h7v7h-7V25z M13,25h7v7h-7V25z M13,34h7v7h-7V34z M20,50h-7v-7h7V50z M29,50h-7v-7h7V50z M38,50h-7v-7h7V50z M47,50h-7   v-7h7V50z M47,41h-7v-7h7V41z M47,25v7h-7v-7H47z" />
                        </g>
                    </svg>
                    <h5 className="cm_wh--desc">06 Μαΐου 2022</h5>
                    <h5 className="cm_wh-time--desc">14:00 - 17:00</h5>
                </div>
            </div>
            <div className="cm_wh__item--wrapper">
                <div className="cm_wh__item">
                    {/*
                        <svg
                            className="cm-ico"
                            viewBox="0 0 24 24"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            aria-labelledby="timeIconTitle"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            style={{ fill: "none", stroke: "#fff" }}>
                            <title id="timeIconTitle">Time</title> <circle cx="12" cy="12" r="10" />
                            <polyline points="12 5 12 12 16 16" />
                        </svg>

                        <h5 className="cm_wh--desc">14:00 - 17:00</h5>
                        */}
                    <svg
                        className="cm-ico"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 297 297"
                        style={{ enableBackground: "new 0 0 297 297" }}>
                        <g>
                            <path d="M148.5,0C87.43,0,37.747,49.703,37.747,110.797c0,91.026,99.729,179.905,103.976,183.645   c1.936,1.705,4.356,2.559,6.777,2.559c2.421,0,4.841-0.853,6.778-2.559c4.245-3.739,103.975-92.618,103.975-183.645   C259.253,49.703,209.57,0,148.5,0z M148.5,272.689c-22.049-21.366-90.243-93.029-90.243-161.892   c0-49.784,40.483-90.287,90.243-90.287s90.243,40.503,90.243,90.287C238.743,179.659,170.549,251.322,148.5,272.689z" />
                            <path d="M148.5,59.183c-28.273,0-51.274,23.154-51.274,51.614c0,28.461,23.001,51.614,51.274,51.614   c28.273,0,51.274-23.153,51.274-51.614C199.774,82.337,176.773,59.183,148.5,59.183z M148.5,141.901   c-16.964,0-30.765-13.953-30.765-31.104c0-17.15,13.801-31.104,30.765-31.104c16.964,0,30.765,13.953,30.765,31.104   C179.265,127.948,165.464,141.901,148.5,141.901z" />
                        </g>
                    </svg>
                    <h5 className="cm_wh--desc">
                        Κεντρικό Κτήριο <br />
                        ΕΚ "Αθηνά", Μαρούσι
                    </h5>
                </div>
            </div>
        </div>
    );
};

export default CampaignWhen;
